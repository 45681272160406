import React from 'react';
import { Columns, Content, Image } from 'react-bulma-components';

export default function Perks() {
  return (
    <>
      <Columns>
        <Columns.Column size={10} offset={1}>
          <Columns
            variableGap={{ mobile: 1, tablet: 4, desktop: 8 }}
            breakpoint="mobile">
            <Columns.Column
              mobile={{ size: 'full' }}
              tablet={{ size: 'half' }}
              widescreen={{ size: 'one-quarter' }}>
              <div style={{ marginBottom: 20 }}>
                <Image src={'/users.png'} />
              </div>
              <Content size="large">
                <h2 className="title is-4">Stay up to date</h2>
                <p>See how many are checked in at your office right now.</p>
              </Content>
            </Columns.Column>
            <Columns.Column
              mobile={{ size: 'full' }}
              tablet={{ size: 'half' }}
              widescreen={{ size: 'one-quarter' }}>
              <div style={{ marginBottom: 20 }}>
                <Image src={'/smile.png'} />
              </div>
              <Content size="large">
                <h2 className="title is-4">Easy to use</h2>
                <p>Check in and out with ease.</p>
              </Content>
            </Columns.Column>
            <Columns.Column
              mobile={{ size: 'full' }}
              tablet={{ size: 'half' }}
              widescreen={{ size: 'one-quarter' }}>
              <div style={{ marginBottom: 20 }}>
                <Image src={'/copy.png'} />
              </div>
              <Content size="large">
                <h2 className="title is-4">Multiple spaces</h2>
                <p>Choose between several office spaces</p>
              </Content>
            </Columns.Column>
            <Columns.Column
              mobile={{ size: 'full' }}
              tablet={{ size: 'half' }}
              widescreen={{ size: 'one-quarter' }}>
              <div style={{ marginBottom: 20 }}>
                <Image src={'/eye-off.png'} />
              </div>
              <Content size="large">
                <h2 className="title is-4">Anonymous</h2>
                <p>Users can set profile as anonymous.</p>
              </Content>
            </Columns.Column>
          </Columns>
        </Columns.Column>
      </Columns>
    </>
  );
}
