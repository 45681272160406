import React from 'react';
import { Helmet } from 'react-helmet';
import {
  Columns,
  Container,
  Content,
  Heading,
  Hero,
  Image,
  Section,
} from 'react-bulma-components';
import Obfuscate from 'react-obfuscate';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import Perks from '../components/Perks';

import './app.scss';
import { iosStoreUrl, androidStoreUrl} from '../../constants';

export default function Home() {
  const breakpoints = useBreakpoint();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Spacetailor by Teamtailor</title>
        <meta property="og:title" content="Spacetailor by Teamtailor" />
        <meta property="og:image" content={'/share-image.png'} />
        <meta
          property="og:description"
          content="Safe office check-ins with Spacetailor"
        />
        <meta property="og:url" content="https://www.spacetailor.io/" />

        <link rel="icon" href="/favicon.png" />
      </Helmet>

      <Hero
        {...(breakpoints.sm && { size: 'fullheight' })}
        className="main-hero">
        <Hero.Head renderAs="header">
          <Section>
            <Container>
              <Columns>
                <Columns.Column size={6} offset={2}>
                  <Image
                    src={'/spacetailor.png'}
                    className="logotype"
                    alt="Spacetailor logo"
                  />
                </Columns.Column>
              </Columns>
              <Columns>
                <Columns.Column size={6} offset={6}>
                  <Heading className="main-heading" size={1}>
                    Safe office check-ins with Spacetailor
                  </Heading>
                  <Container className="store-logos">
                    <a href={iosStoreUrl}>
                      <Image
                        src={'/App Store - eng.png'}
                        alt="Spacetarilor – AppStore"
                      />
                    </a>
                    <a href={androidStoreUrl}>
                      <Image
                        src={'/Google Play - eng.png'}
                        alt="Spacetarilor - Google Play"
                      />
                    </a>
                  </Container>
                </Columns.Column>
              </Columns>
            </Container>
          </Section>
        </Hero.Head>
      </Hero>

      {breakpoints.xs && (
        <Container className="device-container">
          <Image src={'/device.png'} />
        </Container>
      )}

      <Section className="section-perks">
        <Container>
          <Perks />
        </Container>
      </Section>

      <Section>
        <Container breakpoint="desktop">
          <Columns>
            <Columns.Column size={10} offset={1}>
              <div className="video-container">
                <iframe
                  width="854"
                  height="480"
                  src="https://www.youtube.com/embed/i7KimIA2Uq4"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen></iframe>
              </div>
            </Columns.Column>
          </Columns>
        </Container>
      </Section>

      <Section>
        <Container breakpoint="desktop">
          <Columns
            className="is-vcentered"
            variableGap={{ mobile: 1, tablet: 4, desktop: 8 }}>
            {breakpoints.xs && (
              <Columns.Column size={5}>
                <Image src={'/img1.jpg'} />
              </Columns.Column>
            )}
            <Columns.Column size={5} offset={1}>
              <Content size="large">
                <h2 className="is-primary">How it works</h2>
              </Content>
              <Content size="medium">
                <p>
                  See how many people there are at your office at any time, and
                  if there are any slots left for you to take.
                </p>
                <p>
                  Confirm that you follow your company's guidelines to be able
                  to check in, and enjoy a work day from the office.
                </p>
                <p>
                  Check out when you leave and make it possible for a colleague
                  to take that slot.
                </p>
              </Content>
            </Columns.Column>

            {breakpoints.sm && (
              <Columns.Column size={5}>
                <Image src={'/img1.jpg'} />
              </Columns.Column>
            )}
          </Columns>
        </Container>
      </Section>

      <Section>
        <Container breakpoint="desktop">
          <Columns
            className="is-vcentered"
            variableGap={{ mobile: 1, tablet: 4, desktop: 8 }}>
            <Columns.Column size={5} offset={1}>
              <Image src={'/img2.jpg'} />
            </Columns.Column>
            <Columns.Column size={5}>
              <Content size="large">
                <h2>Use your own check-in policy</h2>
              </Content>
              <Content size="medium">
                <p>
                  Make sure your team is informed of the rules at your office.
                  Provide information and remind them to follow the authorities
                  recommendations to minimize the spread of Covid-19.
                </p>
              </Content>
            </Columns.Column>
          </Columns>
        </Container>
      </Section>

      <Section>
        <Container className="footer-store-logos">
          <Columns breakpoint="mobile">
            <Columns.Column mobile={{ size: 'half' }}>
              <div className="is-pulled-right">
                <a href="https://apps.apple.com/app/spacetailor/id1529426388?ign-mpt=uo%3D2">
                  <Image
                    src={'/App Store - eng.png'}
                    alt="Spacetarilor – AppStore"
                  />
                </a>
              </div>
            </Columns.Column>
            <Columns.Column mobile={{ size: 'half' }}>
              <a href="https://play.google.com/store/apps/details?id=io.spacetailor.application">
                <Image
                  src={'/Google Play - eng.png'}
                  alt="Spacetarilor - Google Play"
                />
              </a>
            </Columns.Column>
          </Columns>
        </Container>
      </Section>

      <Section>
        <Container>
          <Columns>
            <Columns.Column size="half" offset="one-quarter">
              <a href="https://www.teamtailor.com/?utm_source=Spacetailor&utm_medium=web&utm_campaign=spacetailor">
                <Image
                  src={'/from_teamtailor.png'}
                  alt="From Teamtailor"
                  className="footer-image"
                />
              </a>
            </Columns.Column>
          </Columns>
        </Container>
      </Section>

      <Section>
        <Container>
          <Columns>
            <Columns.Column>
              <p className="has-text-centered">
                Need support? Contact us and we’ll get back to you as soon as we
                can.
                <br />
                <Obfuscate email="support@spacetailor.io" /> |{' '}
                <a href="/privacy">Privacy Policy</a>
              </p>
            </Columns.Column>
          </Columns>
        </Container>
      </Section>
    </>
  );
}
